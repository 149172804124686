import { Button, List, message, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { SLRoutes } from "../../router/routes";
import { IOrder, IOrderproduct } from "../../services/CartService/cartModels";
import { CartService } from "../../services/CartService/cartService";
import { OrderStatus } from "../../utils/enum";
import "./orderInfo.less";

export const OrderInfo = () => {
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState<IOrder>();
	const params = useParams();
	const navigator = useNavigate();

	useEffect(() => {
		const orderId = params.id;
		if (!orderId || orderId.length !== 36) {
			message.error("Order not found");
			navigator(SLRoutes.Home);
		}
		new CartService()
			.getUserOrder(orderId as string)
			.then((or) => {
				setOrder(or);
				setLoading(false);
			})
			.catch(() => {
				message.error("An error occurred. Please contact support.");
				navigator(SLRoutes.Home);
			});
	}, []);

	const renderItems = () => {
		return (
			<List
				size="large"
				bordered={false}
				dataSource={order ? order.orderproducts : []}
				renderItem={(item: IOrderproduct, index) => {
					return <div style={{minWidth: "500px"}}>
						<div>{index + 1}. {item.product.name}</div>
						<div style={{display: "flex"}}>
							<div style={{paddingRight: "24px"}}><span>Quantity:</span> {item.quantity} </div>
							<div><span>Price:</span> {item.price}/- </div>
						</div>
						<hr/>
					</div>;
				}}
			/>
		);
	};

	const renderActions = () => {
		const orderCanBeCacelledAtStatus = [OrderStatus.Placed, OrderStatus.Confirmed, OrderStatus.Processing, OrderStatus.ReadyToShip, OrderStatus.Shipped, OrderStatus.OutForDelivery];
		const canBeCancelled = orderCanBeCacelledAtStatus.some(s => s === order?.statusid);

		return <>
			{
				canBeCancelled && <Button type="primary">Cancel Order</Button>
			}
			{renderTacking()}
		</>
	}

	const renderTacking = () => {
		return <></>
	}
	
	const renderOrder = () => {
		return (
			<div className="slhome-content" style={{minWidth: "500px"}}>
				<div className="order-info-title">Order Details</div>
				<div>{renderItems()}</div>
				<div>{renderActions()}</div>
			</div>
		);
	};

	return (
		<div className="slhome">
			{loading && (
				<div className="slhome-spin">
					<Spin spinning={loading} />
				</div>
			)}
			{!loading && renderOrder()}
		</div>
	);
};
