export enum ProductImageType {
	Thumb = 1,
	Normal = 2
}

export enum OrderStatus {
	Placed = 1,
	Confirmed = 2,
	Processing = 3,
	ReadyToShip = 4,
	Shipped = 5,
	OutForDelivery = 6,
	UserCancelled = 7,
	SellerCacelled = 8,
	Returned = 9,
	Replacement = 10
}