import { useEffect, useRef, useState } from 'react';
import { GlobalContext, IGlobalContext } from './contexts/globalcontext';
import { SLRouter } from './router/router';
import { Provider } from "react-redux";
import { store } from './store/store';
import "antd/es/style/default.less";
import './App.less';
import './style/index.less';
import { LocalStorageKeys } from './utils/consts';
import { Spin } from 'antd';

function App() {

	const [globalContextValue, setGlobalContextValue] = useState<IGlobalContext>({loginInfo: {
		loggedIn: false
	}});

	const [initalizing, setInitializing] = useState(true);


	useEffect(() => {
		initialCheck();
	}, [globalContextValue.loginInfo])

	const initialCheck = () => {
		// check for localstorage clear
		const token = localStorage.getItem(LocalStorageKeys.token);
		const userInfo = localStorage.getItem(LocalStorageKeys.loggedInUserInformation);
		if ((!token || !userInfo) && globalContextValue.loginInfo?.loggedIn) {
			setGlobalContextValue({
				...globalContextValue,
				loginInfo: {
					loggedIn: false,
					userInfo: undefined
				}
			});
		} else if (token && userInfo && !globalContextValue.loginInfo?.loggedIn) {
			setGlobalContextValue({
				...globalContextValue,
				loginInfo: {
					loggedIn: true,
					userInfo: JSON.parse(userInfo)
				}
			})
		}
		if (initalizing) {
			setInitializing(false);
		}
	}
  if (initalizing) {
	  return <Spin spinning={true}></Spin>
  }
  return <Provider store={store}>
	  <GlobalContext.Provider value={{globalContextValue, setGlobalContextValue}} >
	  <SLRouter/>
  </GlobalContext.Provider>
  </Provider>
}

export default App;
