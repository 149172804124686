import { BaseService } from "../baseService";

export interface ImageService {
	postImage(image: FormData): Promise<string>;
}

export class ImageService extends BaseService implements ImageService {
	
	constructor() {
		super("api/image")
	}

	postImage(image: FormData): Promise<string> {
		return this.upload("", image);
	}
}