import {configureStore} from '@reduxjs/toolkit';
import cartReducer, { ICartInfo } from './cart';

export interface ReduxState {
	cart: ICartInfo;
}

export const store = configureStore({
	reducer: {
		cart: cartReducer
	}
});