import {Layout} from "antd";
import React from "react";
import { SLFooter } from "./Footer";
import { SLHeader } from "./Header";

const { Header, Footer, Content } = Layout;

export interface ISLLayout {
	children: React.ReactNode;
}

export const SLLayout = (props: ISLLayout) => {
	return <Layout style={{height: "100%"}}>
	<Header style={{lineHeight: "56px", height: "56px"}}>
		<SLHeader/>
	</Header>
	<Content style={{minHeight: "calc(100vh - 156px)"}}>{props.children}</Content>
	<Footer style={{background: "#009688", color: "#ffffff", height: "72px"}}>
		<SLFooter/>
	</Footer>
  </Layout>
}