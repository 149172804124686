import { LocalStorageKeys } from "./consts"

export const checkRole = (roles: number[] | undefined) => {
	if (!Array.isArray(roles) || !roles.length) {
		return false;
	}

	if (rolesFromLocalStorage().some(s => roles.some(r => r == s))) {
		return true;
	}

	return false;
}

export const rolesFromLocalStorage = (): number[] => {
	const userInfo: any = localStorage.getItem(LocalStorageKeys.loggedInUserInformation);

	try {
		if (!userInfo || !Array.isArray(JSON.parse(userInfo).roles)) {
			return [];
		}
	} catch {
		return [];
	}

	return JSON.parse(userInfo).roles
}

export const token = () => {
	return localStorage.getItem(LocalStorageKeys.token);
}

export const getPageTitle = (prefix: string) => {
	return `${prefix} | Salasar Agro Products`;
}