import { BaseService } from "../baseService";
import { ICartSaveRequestModel, IOrder } from "./cartModels";

export interface ICartService {
	saveCart(cart: ICartSaveRequestModel): Promise<void>
	getCart(): Promise<string>;
	placeOrder(): Promise<boolean>;
	getUserOrders(): Promise<IOrder[]>;
	getUserOrder(orderId: string): Promise<IOrder>;
}

export class CartService extends BaseService implements ICartService {
	
	constructor() {
		super("api/order")
	}

	saveCart(cart: ICartSaveRequestModel): Promise<void> {
		return this.post("cart", cart);
	}

	getCart(): Promise<string> {
		return this.get("cart");
	}

	placeOrder(): Promise<boolean> {
		return this.post("placeOrder");
	}

	getUserOrders(): Promise<IOrder[]> {
		return this.get("getUserOrders");
	}

	getUserOrder(orderId: string): Promise<IOrder> {
		return this.get("getUserOrder", { orderId })
	}
}