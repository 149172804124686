import { Avatar, Button, Card, InputNumber, Spin, Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../contexts/globalcontext";
import { IProduct } from "../../services/Product/productModels";
import { ProductService } from "../../services/Product/productService";
import {
	addToCartThunk,
	removeCartThunk,
	replaceItemCartThunk,
} from "../../store/cart";
import { ReduxState } from "../../store/store";
import "./home.less";
import { useNavigate } from "react-router";
import { SLRoutes } from "../../router/routes";

const { Meta } = Card;

export const Home = () => {
	useEffect(() => {
		document.title = "Salasar Agro Products | Fresh Vegetables";
		getProducts();
	}, []);

	const [productParams, setProductParams] = useState({
		offset: 0,
		limit: 10,
		isAsc: true,
	});

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const selctedItems = useSelector((state: ReduxState) => state.cart.items);
	const [items, setItems] = useState<IProduct[]>([]);
	const itemRefs: HTMLInputElement[] = useRef([]).current;
	const navigate = useNavigate();

	const globalContext = useContext(GlobalContext);

	const loggedIn = globalContext.globalContextValue?.loginInfo?.loggedIn;
	const dispatcher = useDispatch();

	const getProducts = () => {
		const service = new ProductService();
		service
			.getProducts(
				productParams.offset,
				productParams.limit,
				productParams.isAsc
			)
			.then((items) => {
				setItems(items);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setError(true);
			});
	};

	const addItemToCart = (productId: string, itemIndex: number) => {
		const quantity = parseInt(itemRefs[itemIndex].value);
		const selectedItemEntry = selctedItems.find((i) => i.id === productId);
		if (selectedItemEntry) {
			dispatcher(replaceItemCartThunk({ id: productId, quantity }));
		} else {
			dispatcher(addToCartThunk({ id: productId, quantity }));
		}
	};

	const renderProduct = (product: IProduct, index: number) => {
		const selectedItemEntry = selctedItems.find((i) => i.id === product.id);
		const image = product.images.length
			? product.images[0]
			: "https://dl.dropboxusercontent.com/s/rah7u8vn9c7l0og/random.jpeg";
		const actions = [
			<InputNumber
				defaultValue={(selectedItemEntry?.quantity as any as number) ?? 1}
				ref={(r) => (r != null ? (itemRefs[index] = r) : null)}
				min={1}
				max={items.find((f) => f.id === product.id)?.quantity}
			/>
		];

		if (loggedIn) {
			actions.push(<Button
				type="primary"
				onClick={() => addItemToCart(product.id, index)}
				key="Add"
			>
				{(selectedItemEntry ? "Update" : "Add")}
			</Button>)
		}

		if (!loggedIn) {
			actions.push(<Button
				type="primary"
				onClick={() => navigate(SLRoutes.Login)}
				key="login"
			>
				Login to add
			</Button>)
		}

		if (selectedItemEntry && loggedIn) {
			actions.push(
				<Button
					type="primary"
					onClick={() => loggedIn && dispatcher(removeCartThunk(product.id))}
					key="Remove"
				>
					Remove
				</Button>
			);
		}
		return (
			<Card
				key={product.id}
				style={{ width: "500px", marginTop: 16 }}
				actions={actions}
			>
				<Meta
					style={{ position: "relative" }}
					avatar={<Avatar src={image} shape="square" size={140} />}
					title={product.name}
					description={
						<>
							{product.description}
							<br />
							<br />
							{product.packing} gm or ml pr pack
							<div
								style={{
									fontWeight: 600,
									color: "#009688",
									fontSize: "16px",
									position: "absolute",
									bottom: 0,
								}}
							>
								RS. {product.price}/unit{" "}
								<Tooltip
									overlay={
										"Latest price will used when you will place the order."
									}
								>
									<InfoCircleOutlined />
								</Tooltip>
							</div>
						</>
					}
				/>
			</Card>
		);
	};

	if (error) {
		return <>An Error occurred. Please refresh page.</>
	}

	return (
		<div className="slhome">
			{loading && (
				<div className="slhome-spin">
					<Spin spinning={loading} />
				</div>
			)}
			{!loading && items.length > 0 && (
				<div className="slhome-content">
					{items.map((m, index) => renderProduct(m, index))}
				</div>
			)}
			{
				!loading && items.length === 0 && 
				<div className="slhome-content">No items. Please visit later.</div>
			}
		</div>
	);
};
