import { createSlice, Dispatch, SliceCaseReducers } from "@reduxjs/toolkit";
import { CartService } from "../services/CartService/cartService";
import { IProduct } from "../services/Product/productModels";
import { ReduxState } from "./store";

export interface ICartInfo {
	items: {
		id: string;
		quantity: number;
	}[];
	fetchedInitial: boolean;
	updatingCart: boolean; // Will be used when cart will be updated after initialization
}

export const CartSlice = createSlice<
	ICartInfo,
	SliceCaseReducers<ICartInfo>,
	string
>({
	name: "cart",
	initialState: {
		items: [],
		fetchedInitial: false,
		updatingCart: false
	},
	reducers: {
		add: (state: ICartInfo, action: { payload: IProduct }) => {
			return { ...state, items: [...state.items, action.payload] };
		},
		replace: (state: ICartInfo, action: { payload: IProduct }) => {
			const newItems = state.items.filter((f) => f.id !== action.payload.id);
			return { ...state, items: [...newItems, action.payload] };
		},
		remove: (state: ICartInfo, action: { payload: string }) => {
			return {
				...state,
				items: state.items.filter((f) => f.id !== action.payload),
			};
		},
		removeAll: (state: ICartInfo, action: {payload: undefined}) => {
			return { ...state, items: [] };
		},
		replaceItems: (state: ICartInfo, action: { payload: IProduct[] }) => {
			return { ...state, items: action.payload, fetchedInitial: true };
		},
		setUpdatingCart: (state: ICartInfo, action: {payload: boolean}) => {
			return {...state, updatingCart: action.payload}
		}
	},
});

export const {
	add: addToCart,
	replace: replaceItem,
	remove: removeItemFromCart,
	removeAll: removeAllItemsFromCart,
	replaceItems: replaceItemsFromCart,
	setUpdatingCart
} = CartSlice.actions;

/**
 * Adds a single product to cart
 * @param product {id: string; quantity: number}
 * @returns 
 */
export const addToCartThunk = (product: { id: string; quantity: number }) => {
	return async (dispatch: Dispatch, getState: () => ReduxState) => {
		dispatch(setUpdatingCart(true));
		dispatch(addToCart(product));
		// save state
		const currentState = getState();
		const service = new CartService();
		await service.saveCart({ cartInfo: JSON.stringify(currentState.cart.items) });
		dispatch(setUpdatingCart(false));
	};
};

/**
 * Relace (Updates) an item in cart
 * @param product {id: string; quantity: number}
 * @returns 
 */
export const replaceItemCartThunk = (product: {
	id: string;
	quantity: number;
}) => {
	return async (dispatch: Dispatch, getState: () => ReduxState) => {
		dispatch(setUpdatingCart(true));
		dispatch(replaceItem(product));
		// save state
		const currentState = getState();
		const service = new CartService();
		await service.saveCart({ cartInfo: JSON.stringify(currentState.cart.items) });
		dispatch(setUpdatingCart(false));
	};
};

/**
 * Gets cart information for Backend
 * @returns 
 */
export const getCartThunk = () => {
	return async (dispatch: Dispatch, getState: () => ReduxState) => {
		const service = new CartService();
		const cart = await service.getCart();
		const cartinfo = typeof cart === "string" ? JSON.parse(cart) : cart;
		dispatch(replaceItemsFromCart(cartinfo));
	};
};

/**
 * Removes an item from cart
 * @param id string
 */
export const removeCartThunk = (id: string) => {
	return async (dispatch: Dispatch, getState: () => ReduxState) => {
		dispatch(setUpdatingCart(true));
		dispatch(removeItemFromCart(id));
		// save state
		const currentState = getState();
		const service = new CartService();
		await service.saveCart({ cartInfo: JSON.stringify(currentState.cart.items) });
		dispatch(setUpdatingCart(false));
	}
}

export const removeAllItemsFromCartThunk = () => {
	return async (dispatch: Dispatch, getState: () => ReduxState) => {
		dispatch(setUpdatingCart(true));
		dispatch(removeAllItemsFromCart(undefined));

		// save state
		const currentState = getState();

		const service = new CartService();
		await service.saveCart({ cartInfo: JSON.stringify(currentState.cart.items) });
		dispatch(setUpdatingCart(false));
	}
}

export default CartSlice.reducer;
