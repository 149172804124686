import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { token } from "../utils/func";

export class BaseService {
	protected baseURL =
		process.env.NODE_ENV === "development" ? "http://127.0.0.1:5227/" : "https://api.salasaragroproducts.com/";
	protected controller = "";

	constructor(controller?: string) {
		if (controller) {
			this.controller = controller;
		}
	}

	private handleResponse<T>(response: AxiosResponse<T, any>) {
		if (response.status === 401) {
			window.location.href = "/login";
		}

		return response.data;
	}

	protected async get<T>(
		url: string,
		queryParams?: object,
		config: AxiosRequestConfig = {}
	) {
		const tokenToSend = token();
		const authHeader: any = tokenToSend
			? {
					Authorization: "Bearer " + tokenToSend,
			  }
			: {};
		url = url.length ? "/" + url : "";

		const t = await Axios.get(this.baseURL + this.controller + url, {
			params: queryParams,
			headers: {
				...authHeader,
			},
			responseType: "json",
			...config,
		});
		return this.handleResponse<T>(t);
	}

	protected async post<T>(
		url: string,
		data?: object,
		config: AxiosRequestConfig = {}
	) {
		const tokenToSend = token();
		const authHeader: any = tokenToSend
			? {
					Authorization: "Bearer " + tokenToSend,
			  }
			: {};

		url = url.length ? "/" + url : "";

		const t = await Axios.post(this.baseURL + this.controller + url, data, {
			headers: {
				...authHeader,
			},
			responseType: "json",
			...config,
		});
		return this.handleResponse<T>(t);
	}

	protected async upload<T>(
		url: string,
		data?: FormData,
		config: AxiosRequestConfig = {}
	) {
		const tokenToSend = token();
		const authHeader: any = tokenToSend
			? {
					Authorization: "Bearer " + tokenToSend,
			  }
			: {};

		url = url.length ? "/" + url : "";
		const t = await Axios.post(this.baseURL + this.controller + url, data, {
			headers: {
				...authHeader
			},
			responseType: "json",
			...config,
		});
		return this.handleResponse<T>(t);
	}
}
