import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GlobalContext } from "../contexts/globalcontext";
import { AddProduct } from "../pages/AddProduct/addProduct";
import { CartPage } from "../pages/Cart/cart";
import { Contact } from "../pages/Contact/contact";
import { Home } from "../pages/Home/Home";
import { Login } from "../pages/Login/Login";
import { NoAccess } from "../pages/NoAccess/NoAccess";
import { OrderInfo } from "../pages/Order/orderInfo";
import { OrderPage } from "../pages/Orders/order";
import { getCartThunk } from "../store/cart";
import { ReduxState } from "../store/store";
import { SLRoutes } from "./routes";
import { SLRoute } from "./SLRoute";

export const SLRouter = () => {
	// get initial cart
	const globalContext = useContext(GlobalContext);
	const currentState = useSelector((state: ReduxState) => state.cart);
	const dispatcher = useDispatch();

	const loggedIn = globalContext.globalContextValue?.loginInfo?.loggedIn;
	useEffect(() => {
		if (loggedIn && !currentState.fetchedInitial) {		
			dispatcher(getCartThunk())
		}
	}, [loggedIn])

	return (
		<Router>
			<Routes>
				<Route path={SLRoutes.Login} element={<SLRoute component={Login}/>} key={SLRoutes.Login}/>

				<Route path={SLRoutes.NoAccess} element={<SLRoute component={NoAccess} />} key={SLRoutes.NoAccess}/>
				<Route path={SLRoutes.Home} element={<SLRoute protectedRoute={false} component={Home} />} key={SLRoutes.Home}/>
				<Route path={SLRoutes.AddProduct} element={<SLRoute protectedRoute={true} component={AddProduct} />} key={SLRoutes.AddProduct}/>
				<Route path={SLRoutes.Cart} element={<SLRoute protectedRoute={true} component={CartPage} />} key={SLRoutes.Cart}/>
				<Route path={SLRoutes.Orders} element={<SLRoute protectedRoute={true} component={OrderPage} />} key={SLRoutes.Orders}/>
				<Route path={SLRoutes.OrderInfo} element={<SLRoute protectedRoute={true} component={OrderInfo} />} key={SLRoutes.OrderInfo}/>
				<Route path={SLRoutes.Contact} element={<SLRoute protectedRoute={false} component={Contact} />} key={SLRoutes.Contact}/>
			</Routes>
		</Router>
	);
};
