import "./contact.less";
export const Contact = () => {
	return (
		<div className="con-container">
			<div>
				<div>
					<span style={{ fontWeight: 600 }}>Email: </span>&nbsp;
					<a style={{ color: "#009688" }} href="mailto:ashupanwar18@gmail.com">
						ashupanwar18@gmail.com
					</a>
				</div>
				<div>
					<span style={{ fontWeight: 600 }}>Phone:</span>&nbsp;7206547266
				</div>
			</div>
		</div>
	);
};
