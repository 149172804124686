import { SLLayout } from "../Layout/Layout";
import { checkRole } from "../utils/func";
import { useNavigate } from "react-router-dom";
import { SLRoutes } from "./routes";
import { GlobalContext } from "../contexts/globalcontext";
import { useContext, useEffect, useState } from "react";

interface SLRouteProps {
	component: (props?: any) => JSX.Element;
	protectedRoute?: boolean;
	checkRoles?: boolean;
	allowedRoles?: number[];
}

export const SLRoute = (props: SLRouteProps) => {
	const {
		component: Component,
		protectedRoute,
		allowedRoles,
		checkRoles
	} = props;
	
	const navigation = useNavigate();
	const globalContext = useContext(GlobalContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (protectedRoute) {
			if (checkRoles && checkRole(allowedRoles)) {
				navigation(SLRoutes.NoAccess);
			}
			console.log(globalContext.globalContextValue);
			if (!globalContext.globalContextValue?.loginInfo?.loggedIn) {
				navigation(SLRoutes.Login);
			}
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		if (!globalContext.globalContextValue?.loginInfo?.loggedIn && props.protectedRoute) {
			navigation(SLRoutes.Home);
		}
	}, [globalContext.globalContextValue?.loginInfo])

	if (loading) {
		return <></>
	}

	return (
		<SLLayout>
			<Component />
		</SLLayout>
	);
};
