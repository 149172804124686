import { Badge, Button, Dropdown, Input } from "antd";
import ShopOutlined from "@ant-design/icons/ShopOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { useContext } from "react";
import { GlobalContext } from "../contexts/globalcontext";
import Logo from "../images/logo.png";
import { useSelector } from "react-redux";
import { ReduxState } from "../store/store";
import { useLocation, useNavigate } from "react-router";
import { SLRoutes } from "../router/routes";
import { LocalStorageKeys } from "../utils/consts";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import "./header.less";

export const SLHeader = () => {
	const globalContext = useContext(GlobalContext);
	const cartState = useSelector((state: ReduxState) => state.cart);
	const loggedIn = globalContext.globalContextValue?.loginInfo?.loggedIn;
	const navigation = useNavigate();
	const location = useLocation();

	const isLoginPage = location.pathname === SLRoutes.Login;
	const isCartPage = location.pathname === SLRoutes.Cart;
	const isAdmin =
		globalContext.globalContextValue?.loginInfo?.userInfo?.isAdmin;

	const logout = () => {
		localStorage.removeItem(LocalStorageKeys.token);
		localStorage.removeItem(LocalStorageKeys.loggedInUserInformation);
		window.location.href = "/";
	};

	const headerItems = () => {
		return (
			<>
				{loggedIn && (
					<>
						{!isCartPage && (
							<Badge count={cartState.items.length} showZero={false}>
								<div
									onClick={() =>
										loggedIn &&
										!cartState.updatingCart &&
										navigation(SLRoutes.Cart)
									}
									style={{
										color: cartState.updatingCart ? "grey" : "white",
										fontSize: "16px",
										fontWeight: "bold",
										cursor: cartState.updatingCart ? "not-allowed" : "pointer",
									}}
								>
									{cartState.updatingCart ? (
										<LoadingOutlined />
									) : (
										<>
											<ShopOutlined /> Cart
										</>
									)}
								</div>
							</Badge>
						)}
						<div onClick={logout} className="icon-common">
							<UserOutlined /> Logout
						</div>

						{isAdmin && (
							<Button
								type="primary"
								onClick={() => navigation(SLRoutes.AddProduct)}
							>
								Add Product
							</Button>
						)}
					</>
				)}
				{!loggedIn && !isLoginPage && (
					<>
						<div
							className="icon-common"
							onClick={() => navigation(SLRoutes.Login)}
						>
							<UserOutlined /> Login/Register
						</div>
					</>
				)}
			</>
		);
	};

	const renderRightHeader = () => {
		return (
			<div className="menu-con">
				<div className="barIcon">
					<Dropdown
						overlay={<div className="dropdown-meanu">{headerItems()}</div>}
					>
						<Button icon={<MenuOutlined />}></Button>
					</Dropdown>
				</div>
				<span className="desktop-menu">{headerItems()}</span>
			</div>
		);
	};

	return (
		<>
			<div
				style={{
					display: "flex",
					height: "100%",
					justifyContent: "space-around",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", alignItems: "center", width: "100%" }}>
					<img
						alt="Salasar Agro"
						src={Logo}
						style={{ maxHeight: "48px", cursor: "pointer" }}
						onClick={() => navigation(SLRoutes.Home)}
					/>
					<div
						style={{
							marginLeft: "24px",
							display: "flex",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Input.Search style={{ maxWidth: "300px" }}></Input.Search>
					</div>
				</div>
				{renderRightHeader()}
			</div>
		</>
	);
};
