import {
	Button,
	Card,
	Form,
	Input,
	InputNumber,
	message,
	Select,
	Upload,
} from "antd";
import { useState } from "react";
import { useNavigate } from "react-router";
import { SLRoutes } from "../../router/routes";
import { ProductService } from "../../services/Product/productService";
import { UploadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from 'uuid';
import { ImageService } from "../../services/ImageService/imageService";

export const AddProduct = () => {
	const [addingProduct, setAddingProduct] = useState(false);
	const navigation = useNavigate();

	const onFinish = async (values: any) => {
		const image: File = values.image[0].originFileObj;
		const name: string[] = image.name.split(".");
		const ext = name[name.length - 1];
		const newName = `${uuidv4()}.${ext}`;
		
		setAddingProduct(true);
		let url = "";
		try {
			const formData = new FormData();
			formData.append("file", image, newName)
			 url = await uploadImage(formData);
			 console.log(url);
			 setAddingProduct(false);
			 return;
		} catch(e: any) {
			setAddingProduct(false);
			message.error("An Error Occurred.");
		}
		
		delete values.image;
		
		new ProductService()
			.addProduct({
				...values,
				ImageUrl: url, 
				status: getStatus(values.status),
			})
			.then(() => {
				message.success("Product Addedd Successfully");
				navigation(SLRoutes.Home);
			})
			.catch(() => {
				setAddingProduct(false);
				message.error("An Error Occurred.");
			});
	};

	const uploadImage = async (image: FormData): Promise<any> => {
		return new ImageService().postImage(image);
	}

	const onFinishFailed = (errorInfo: any) => {
		message.error("One or more fields are required.");
	};

	const getStatus = (s: string) => {
		switch (s) {
			case "tempNonAvailable":
				return 2;
			case "enabled":
				return 1;
			default:
				return 0;
		}
	};

	const getFile = (e: any) => {
		console.log("Upload event:", e);

		if (Array.isArray(e)) {
			return e;
		}
		// only keep one file
		const file = e && e.fileList.length ? [e.fileList[e.fileList.length - 1]] : [];

		if (file.length && !(file[0].type === 'image/jpeg' || file[0].type === 'image/png' || (file[0].size / 1024 / 1024 < 2))) {
			return [];
		} 
		return file;
	};

	const dummyRequest = ({ onSuccess }: any) => {
		// The success callback does not work without the timeout. Reason: Unknown.
		setTimeout(() => {
			onSuccess("OK");
		}, 0);
	};

	const beforeUpload = (file: File) => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
		if (!isJpgOrPng) {
		  message.error('You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
		  message.error('Image must smaller than 2MB!');
		}
		return isJpgOrPng && isLt2M;
	  }

	return (
		<Card
			style={{
				margin: "16px",
				border: "1px solid rgb(0 150 136 / 65%)",
				borderRadius: "8px",
			}}
		>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					label="Image"
					name="image"
					rules={[{ required: true, message: "Please select image!" }]}
					valuePropName="fileList"
					initialValue={[]}
					getValueFromEvent={getFile}
				>
					<Upload
						multiple={false}
						beforeUpload={beforeUpload}
						customRequest={dummyRequest}
					>
						<Button icon={<UploadOutlined />}>Select</Button>
					</Upload>
				</Form.Item>
				<Form.Item
					label="Name"
					name="name"
					rules={[
						{ required: true, message: "Please input name of product!" },
						{ max: 200, message: "name should be less than 200 in length" },
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="Description"
					name="description"
					rules={[{ required: true, message: "Please input description!" }]}
				>
					<Input.TextArea />
				</Form.Item>

				<Form.Item
					label="Packing Size"
					name="packing"
					help="In grams"
					rules={[{ required: true, message: "Please input Packing!" }]}
				>
					<InputNumber />
				</Form.Item>

				<Form.Item
					label="Price"
					name="price"
					rules={[{ required: true, message: "Please input price!" }]}
				>
					<InputNumber precision={2} decimalSeparator="." step={0.01} />
				</Form.Item>

				<Form.Item
					label="Status"
					name="status"
					initialValue="enabled"
					rules={[{ required: true, message: "Please input your Quantity!" }]}
				>
					<Select>
						<Select.Option value="enabled">Enabled</Select.Option>
						<Select.Option value="disabled">Disabled</Select.Option>
						<Select.Option value="tempNonAvailable">
							Temporary Unavailable
						</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					label="Quantity"
					name="quantity"
					rules={[{ required: true, message: "Please input your Quantity!" }]}
				>
					<InputNumber />
				</Form.Item>

				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button type="primary" htmlType="submit" loading={addingProduct}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Card>
	);
};
