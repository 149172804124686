import { BaseService } from "../baseService";
import { ISendOTPResponseModel, IVerifyOTPResponseModel } from "./authModels";

export interface IAuthService {
	sendOTP: (phone: string) => Promise<ISendOTPResponseModel | null>;
	verifyOTP: (sessionId: string, otp: string, phone: string) => Promise<IVerifyOTPResponseModel | null> 
}

export class AuthService extends BaseService implements IAuthService {
	
	constructor() {
		super("Auth")
	}

	sendOTP(phone: string): Promise<ISendOTPResponseModel | null> {
		return this.get("sendOTP", {phone})
	}

	verifyOTP(sessionId: string, otp: string, phone: string): Promise<IVerifyOTPResponseModel | null>  {
		return this.get("verifyOTP", {sessionId, otp, phone})
	}
}