import { Form, Input, Button, Checkbox, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "../../contexts/globalcontext";
import { SLRoutes } from "../../router/routes";
import { AuthService } from "../../services/AuthService/authService";
import { LocalStorageKeys } from "../../utils/consts";
import { getPageTitle } from "../../utils/func";
import "./login.less";

export const Login = (props: any) => {
	const [sendingOTP, setSendingOTP] = useState(false);
	const [verifyingOTP, setVerifyingOTP] = useState(false);
	const [otpSessionId, setOTPSessionId] = useState("");
	const [timer, setTimer] = useState(0);
	const authService = new AuthService();
	const [form] = Form.useForm();
	const globalContext = useContext(GlobalContext);
	const navigation = useNavigate();

	useEffect(() => {
		document.title = getPageTitle("Login/Register");
		if (globalContext.globalContextValue?.loginInfo?.loggedIn) {
			navigation(SLRoutes.Home);
		}
		return () => {
			if (timer > 0) {
				clearTimeout(timer)
			}
		}
	}, []);

	useEffect(() => {
		if (timer > 0) {
			setTimeout(() => setTimer(timer - 1), 1000);
		}
	}, [timer]);

	const onFinish = async (values: any, resend: boolean = false) => {
		// otp verification
		if (otpSessionId.length && !resend) {
			verifyOTP(values);
			return;
		}
		// send OTP
		setSendingOTP(true);
		try {
			const sessionId = await authService.sendOTP(values.username);
			if (sessionId && sessionId.status === "Success") {
				setOTPSessionId(sessionId.details);
				setSendingOTP(false);
				setTimer(60);
				return;
			}
			throw new Error("");
		} catch (e) {
			setSendingOTP(false);
			message.error("Unable to send OTP. Please contact support.");
		}
	};

	const verifyOTP = async (values: any) => {
		if (values.otp && values.otp.length) {
			setVerifyingOTP(true);
		}
		try {
			const response = await authService.verifyOTP(
				otpSessionId,
				values.otp,
				values.username
			);
			if (response) {
				setVerifyingOTP(false);
				// set token and userinformation to storage and globalContext
				localStorage.setItem(LocalStorageKeys.loggedInUserInformation, JSON.stringify({
					id: response.id,
					phone: response.phone,
					isAdmin: response.isAdmin
				}));
				localStorage.setItem(LocalStorageKeys.token, response.token);
				if (globalContext.setGlobalContextValue) {
					globalContext.setGlobalContextValue({
						...globalContext.globalContextValue,
						loginInfo: {
							loggedIn: true,
							userInfo: {
								id: response.id,
								phone: response.phone,
								isAdmin: response.isAdmin
							}
						}
					})
				}
				navigation(SLRoutes.Home);
				return;
			}
			throw new Error("");
		} catch (e) {
			setVerifyingOTP(false);
			message.error("Unable to send OTP. Please contact support.");
		}
	};

	const onFinishFailed = (errorInfo: any) => {};

	return (
		<div className="lcontain">
			<div className="lcontain__form">
				<Form
					name="login"
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 18 }}
					initialValues={{ remember: true }}
					onFinish={onFinish}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Form.Item
						label="Phone"
						labelAlign="left"
						name="username"
						className="lcontain__form-row"
						rules={[
							{ required: true, message: "Please input your phone number!" },
							{ min: 10, message: "Phone should of 10 numbers" },
							{ max: 10, message: "Phone should of 10 numbers" },
						]}
					>
						<Input type="tel" disabled={!!otpSessionId.length} />
					</Form.Item>
					{!!otpSessionId.length && (
						<Form.Item
							label="OTP"
							labelAlign="left"
							name="otp"
							className="lcontain__form-row"
							rules={[{ required: true, message: "Please input OTP!" }]}
						>
							<Input type="tel" />
						</Form.Item>
					)}

					<Form.Item wrapperCol={{ offset: 9, span: 8 }}>
						<Button
							type="primary"
							htmlType="submit"
							disabled={sendingOTP}
							loading={sendingOTP || verifyingOTP}
						>
							{otpSessionId.length ? "Verify OTP" : "Send OTP"}
						</Button>
					</Form.Item>
				</Form>
				{!!otpSessionId.length && (
					<span>
						&nbsp;&nbsp;
						{timer > 0 ? (
							`Resend in ${timer}`
						) : (
							<Button
								type="link"
								onClick={() => onFinish(form.getFieldsValue, true)}
							>
								Resend OTP
							</Button>
						)}
					</span>
				)}
			</div>
		</div>
	);
};
