import React from "react";

export interface IGlobalContext {
	loginInfo?: {
		loggedIn?: boolean;
		userInfo?: IUser;
	}
}

export interface IGlobalContextProvider {
	globalContextValue?: IGlobalContext;
	setGlobalContextValue?: React.Dispatch<React.SetStateAction<IGlobalContext>>;
}

interface IUser {
	id: string;
	phone: string;
	isAdmin: boolean;
}

export const GlobalContext = React.createContext<IGlobalContextProvider>({});