import { Typography } from "antd";
import { useNavigate } from "react-router";
import { SLRoutes } from "../router/routes";
import "./footer.less";

export const SLFooter = () => {
	const navigatore = useNavigate();
	return (
		<div style={{ display: "flex", justifyContent: "space-between" }}>
			<div id="left-footer" style={{ fontSize: "16px", fontWeight: "bold" }}>
				
				<span className="sl-copy">Salasar Agro Products &copy; {new Date().getFullYear()}</span>
				<span className="sl-copy-small">SAP &copy; {new Date().getFullYear()}</span>
			</div>
			<div id="right-footer" style={{ display: "flex", fontSize: "16px" }}>
				<div style={{ fontWeight: "bold" }}>Contact: </div>
				<div>
					{" "}
					<span className="slphone">&nbsp;7206547266</span>&nbsp;{" "}
					<Typography.Link onClick={() => navigatore(SLRoutes.Contact)} style={{color: "white"}}>
						More
					</Typography.Link>
				</div>
			</div>
		</div>
	);
};
