import { InfoCircleOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, InputNumber, message, Spin, Tooltip, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { GlobalContext } from "../../contexts/globalcontext";
import { SLRoutes } from "../../router/routes";
import { CartService } from "../../services/CartService/cartService";
import { IProduct } from "../../services/Product/productModels";
import { ProductService } from "../../services/Product/productService";
import { removeAllItemsFromCartThunk, removeCartThunk, replaceItemCartThunk } from "../../store/cart";
import { ReduxState } from "../../store/store";

export const CartPage = () => {
	const selctedItems = useSelector((state: ReduxState) => state.cart.items);
	
	const globalContext = useContext(GlobalContext);
	const [loading, setLoading] = useState(true);
	const [placingOrder, setPlacingOrder] = useState(false);
	const [error, setError] = useState(false);
	const [items, setItems] = useState<IProduct[]>([]);
	const itemRefs: HTMLInputElement[] = useRef([]).current;
	const navigation = useNavigate();

	const loggedIn = globalContext.globalContextValue?.loginInfo?.loggedIn;
	const dispatcher = useDispatch();

	useEffect(() => {
		// get cart items
		const service = new ProductService();
		service.getCartProducts()
		.then(t => {
			setItems(t);
			setLoading(false);
		})
		.catch(() => {
			setError(true);
			setLoading(false);
			message.error("An error occurred.")
		});
	}, [])

	const updateItemToCart = (productId: string, itemIndex: number) => {
		const quantity = parseInt(itemRefs[itemIndex].value);
		dispatcher(replaceItemCartThunk({ id: productId, quantity }));
	};

	const removeItemFromCart = (productId: string, itemIndex: number) => {
		const newItems = items.filter(f => f.id !== productId);
		dispatcher(removeCartThunk(productId));
		setItems(newItems);
	}

	const renderProduct = (product: IProduct, index: number) => {
		const selectedItemEntry = selctedItems.find((i) => i.id === product.id);
		const image = product.images.length
			? product.images[0]
			: "https://dl.dropboxusercontent.com/s/rah7u8vn9c7l0og/random.jpeg";
		const actions = [
			<InputNumber
				defaultValue={(selectedItemEntry?.quantity as any as number) ?? 1}
				ref={(r) => (r != null ? (itemRefs[index] = r) : null)}
				min={1}
				max={items.find((f) => f.id === product.id)?.quantity}
			/>,
			<Button
				type="primary"
				onClick={() => loggedIn && updateItemToCart(product.id, index)}
				key="setting"
			>
				Update
			</Button>,
		];

		if (selectedItemEntry && loggedIn) {
			actions.push(
				<Button
					type="primary"
					onClick={() => loggedIn && removeItemFromCart(product.id, index)}
					key="setting"
				>
					Remove
				</Button>
			);
		}
		return (
			<Card
				key={product.id}
				style={{ width: "500px", marginTop: 16 }}
				actions={actions}
			>
				<Meta
					style={{ position: "relative" }}
					avatar={<Avatar src={image} shape="square" size={140} />}
					title={product.name}
					description={
						<>
							{product.description}
							<br />
							<br />
							{product.packing} gm or ml pr pack
							<div
								style={{
									fontWeight: 600,
									color: "#009688",
									fontSize: "16px",
									position: "absolute",
									bottom: 0,
								}}
							>
								RS. {product.price}/unit{" "}
								<Tooltip
									overlay={
										"Latest price will be used when you will place the order."
									}
								>
									<InfoCircleOutlined />
								</Tooltip>
							</div>
						</>
					}
				/>
			</Card>
		);
	};

	const calculateAmount = () => {
		return selctedItems.map(m => {
			const item = items.find(f => f.id === m.id);
			return item ? item.price * m.quantity : 1;
		}).reduce((p, c) => p + c, 1);
	}

	const placeOrder = () => {
		setPlacingOrder(true);
		const service = new CartService();

		service.placeOrder()
		.then(t => {
			if (t) {
				// empty cart
				dispatcher(removeAllItemsFromCartThunk());
				// show success
				message.success("Order placed.");
				// go to home for now
				navigation(SLRoutes.Home);
			} else {
				message.error("Unable to place order. Please try again.");
			}

			setPlacingOrder(false);
		})
		.catch(() => {
			// show error
			message.error("Unable to place order. Please try again.");
			setPlacingOrder(false);
		});
	}

	if (error) {
		return <>An Error occurred. Please refresh page.</>
	}
	
	return <>
	<div className="slhome">
			{loading && (
				<div className="slhome-spin">
					<Spin spinning={loading} />
				</div>
			)}
			{!loading && items.length > 0 && (
				<Spin spinning={placingOrder}>
				<div className="slhome-content">
					<div><Button type="primary" onClick={placeOrder}>Place order (Rs. {calculateAmount()})</Button></div>
					{items.map((m, index) => renderProduct(m, index))}
				</div>
				</Spin>
			)}
			{
				!loading && items.length === 0 && <div className="slhome-content">
				Please add some items. <Typography.Link onClick={() => navigation(SLRoutes.Home)}> Add Now </Typography.Link>
			</div>
			}
		</div>
	</>
}