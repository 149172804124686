import { BaseService } from "../baseService";
import { IProduct, IProductAddRequestModel } from "./productModels";

export interface IProductService {
	getProducts(offset: number, limit: number, isAsc?: boolean, orderBy?: string): Promise<IProduct[]>;
	addProduct(data: IProductAddRequestModel): Promise<void>;
	getCartProducts(): Promise<IProduct[]>;
}

export class ProductService extends BaseService implements IProductService {
	
	constructor() {
		super("api/product")
	}

	getProducts(offset: number, limit: number, isAsc: boolean = false, orderBy: string = "createdon"): Promise<IProduct[]> {
		return this.get("", {offset, limit, isAsc, orderBy})
	}

	addProduct(data: IProductAddRequestModel): Promise<void> {
		return this.post("", data);
	}

	getCartProducts(): Promise<IProduct[]> {
		return this.get("cartProduct");
	}
}