import { List, Modal, Spin } from "antd";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { SLRoutes } from "../../router/routes";
import { IOrder } from "../../services/CartService/cartModels";
import { CartService } from "../../services/CartService/cartService";
import { OrderStatus } from "../../utils/enum";
import "./order.less";

export const OrderPage = () => {
	const [orders, setOrders] = useState<IOrder[]>([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const navigator = useNavigate();

	useEffect(() => {
		new CartService()
			.getUserOrders()
			.then((orders) => {
				setLoading(false);
				setOrders(orders);
			})
			.catch(() => {
				setLoading(false);
				setError(false);
			});
	}, []);

	const calculatePrice = (order: IOrder) => {
		return order.orderproducts.reduce((p, n) => p + n.price * n.quantity, 0);
	};

	const goToOrderDetailsPage = (id: string) => {
		navigator(generatePath(SLRoutes.OrderInfo, {id}));
	};

	const cancelOrder = (id: string) => {
		Modal.confirm({
			title: "Do you really want to cancel order?",
			onOk: () => {
				
			},
		});
	};

	const moreProducts = (item: IOrder) => {
		if (item.orderproducts.length > 1) {
			return ` and ${item.orderproducts.length - 1} more`;
		}
		return "";
	};

	const getDate = (time: string) => {
		return DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_FULL);
	};

	const orderCanBeCacelledAtStatus = useRef([OrderStatus.Placed, OrderStatus.Confirmed, OrderStatus.Processing, OrderStatus.ReadyToShip, OrderStatus.Shipped, OrderStatus.OutForDelivery]).current;
	
	const renderOrders = () => {
		return (
			<List
				size="large"
				bordered={false}
				dataSource={orders}
				renderItem={(item) => {
					const orderstatus = item.statusid;
					const showCancel = orderCanBeCacelledAtStatus.some(s => s === orderstatus);
					const otherStatus = orderstatus === OrderStatus.UserCancelled || OrderStatus.SellerCacelled
											? "Cancelled"
											: orderstatus === OrderStatus.Returned
												? "Returned"
												: orderstatus === OrderStatus.Replacement
													? "Replacement"
													: ""
					return (
						<List.Item className="order-item">
							<div className="order-date">{getDate(item.creationdate)}</div>
							<div className="order-title">
								{item.orderproducts[0].product.name} {moreProducts(item)}
							</div>
							<span style={{ fontWeight: "bold" }}>Price:</span> Rs.{" "}
							{calculatePrice(item)}/-
							<div
								className="order-details-btn"
								onClick={() => goToOrderDetailsPage(item.id)}
							>
								View Details
							</div>
							{showCancel &&
								<div
									className="order-cancel-btn"
									onClick={() => cancelOrder(item.id)}
								>
									Cancel Order
								</div>
							}
							{
								!showCancel && <div className="order-other-status">
											{otherStatus}
								</div>
							}
						</List.Item>
					);
				}}
			/>
		);
	};

	if (error) {
		return <>An error occurred. Please try again.</>;
	}
	return (
		<div className="slhome">
			{loading && (
				<div className="slhome-spin">
					<Spin spinning={loading} />
				</div>
			)}
			{!loading && orders.length > 0 && (
				<div className="slhome-content">{renderOrders()}</div>
			)}
		</div>
	);
};
